const axios = require('axios')

export class IPLocator {
  options = {
    ipaddress: '0.0.0.0'
  }

  constructor (options) {
    this.options = {
      ...this.options,
      ...options
    }
  }

  search () {
    return axios.get(
      'https://api.ipgeolocation.io/ipgeo?apiKey=040b11757cd54c728af713458299f174&ip=' +
        this.options.ipaddress
    )
  }
}

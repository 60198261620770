<template>
  <div class="page page-tools-random">
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>IP Locator</h1>
          <hr />
        </div>
      </div>
    </div>
    <form class="container" @submit.prevent="searchIP">
      <div class="form-group row">
        <label for="input-ipaddress" class="col-sm-2 col-form-label"
          >IP Address</label
        >
        <div class="col-sm-10">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="input-ipaddress"
              placeholder="IP Address"
              name="ipaddress"
              :value="ipaddress"
              ref="inIPAddress"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="getClientIP"
              >
                Get My IP
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10 col-form-label">
          <button type="submit" class="btn btn-primary">
            <i class="fa-solid fa-location-crosshairs"></i> Search
          </button>
        </div>
      </div>
      <template v-if="result">
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">IP</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['ip'] }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">Continent</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['continent_name'] }} ({{ result['continent_code'] }})
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">Country</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['country_name'] }} ({{ result['country_code3'] }})
            <img
              :src="result['country_flag']"
              :alt="result['country_code3']"
              style="height: 1em"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">District</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['district'] }}, {{ result['city'] }},
            {{ result['state_prov'] }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">Coordinates</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['latitude'] }}, {{ result['longitude'] }}<br />(
            <a
              :href="
                'https://www.google.com/maps/search/' +
                result['latitude'] +
                ',' +
                result['longitude']
              "
              target="_blank"
              >View in Google Map</a
            >
            )
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">ISP</div>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">
            {{ result['isp'] }}, {{ result['organization'] }}<br />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-12 col-sm-10"><br />Raw Data:</div>
        </div>
        <div class="row" v-for="(value, key) in result" :key="key">
          <label class="col-sm-2"></label>
          <label class="col-4 col-sm-4 col-md-3 col-lg-2">{{ key }}</label>
          <div class="col-8 col-sm-6 col-md-7 col-lg-8">{{ value }}</div>
        </div>
      </template>
    </form>
    <div class="container">
      <div class="row">
        <div class="col">
          <i
            >Powered by
            <a href="https://app.ipgeolocation.io/" target="_blank"
              >https://app.ipgeolocation.io/</a
            ></i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IPLocator } from '@/module/IPLocator.js'
const axios = require('axios')

export default {
  name: 'IPLocator',
  data: function () {
    return {
      ipaddress: '0.0.0.0',
      result: null
    }
  },
  methods: {
    getClientIP () {
      var self = this
      axios.get(this.$store.state.endpoint + 'ip_address').then((response) => {
        try {
          response = response.data
          if (response.status !== 'success') {
            throw response.error.length > 0
              ? response.error[0]
              : 'Unknown Error'
          }
          self.ipaddress = response.data
          self.$refs.inIPAddress.value = self.ipaddress
        } catch (e) {
          console.error(e)
        }
      })
    },
    searchIP () {
      var self = this
      self.ipaddress = self.$refs.inIPAddress.value
      var ipLocator = new IPLocator({
        ipaddress: self.ipaddress
      })
      self.result = null
      ipLocator
        .search()
        .then((response) => {
          self.result = response.data
        })
        .catch((error) => {
          alert(error)
        })
    }
  },
  beforeMount () {
    this.getClientIP()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/toolshead.scss';

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
</style>
